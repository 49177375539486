
/* only used with webgl, only used with aside */
canvas {
	display: block;
	border: none;
	background-color: #088;
}

.webgl-chart {
	cursor: grab;
	float: left;
}

.webgl-chart:active {
	cursor: grabbing;
}

/* container for all tic labels */
aside {
	display: block;
	position: absolute;  /* overlays the canvas */
	z-index: 10;
	background: transparent;
	color: #eee;
	width: 100%;  /* all the area of the canvas */
	height: 100%;
	overflow: hidden;
}

/* tic label */
tic-lab {
	position: absolute;
	font-size: .86em;
	border-radius: 1em / .5em;
	background-color: #0004;
	padding: 0 0;
	box-shadow: #0004 0 0 .43em .43em;
}

tic-lab i {
	font-family: palatino, times, serif;
	font-style: italic;
}
