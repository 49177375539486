/* ******************************************************* blurb  */
#blurb-box {
	margin: .25em;
	padding: .25em;
	background-color: black;
	background-color: #0008;
	color: white;
	cursor: default;
}

.blurb-title {
	font-size: 1.5em;
}

.blurb-text {
	padding: 1em;
}

/* ******************************************************* nav bar */
/* one of those little square gray buttons, or the Next button which is wider */
div.nav-bar {
	margin-left: -.167em
}

/* each button */
div.nav-bar div {
	display: inline-block;
	line-height: 1.5em;
	font-family: palatino, georgia, publico text, times new roman, serif;
	padding: .5em;
	margin: .167em;
	border: none;
	cursor: default;
	background: #444;
}

/* extra for current scene */
div.nav-bar div.selected {
	font-weight: bold;
	background: #999;
}

/* ******************************************************* instructions */
.desktop-instructions, .mobile-instructions, .3D-instructions {
	width: 11em;
	height: 5em;
	padding: .2em;
	margin: 0 .5em .5em 0;
	background-color: #662;
	border: white 1px solid;
}

/* italic style used for variables in function formulas */
#blurb-box v {
	display: inline;
	font-family: times, serif;
	font-style: italic;
}

.colorExample {
	width: 7em;
	line-height: 2em;
	border: 2px #aaa outset;
	color: black;
	text-align: center;
	font-family: Times New Roman, serif;
	margin: 2px 0;
}

.red {background-color: #f00}
.chartreuce {background-color: #8f0}
.cyan {background-color: #0ff}
.purple {background-color: #80f}


/* ******************************************************* responsive */
/* this decides two general layouts of the screen:
	* smaller screens: hamburger menu button shows up, blurb becomes menu
	* larger screeens: blurb is just off to the side, static
	*** KEEP THIS IN SYNC WITH SIMILAR EXPRESSIONS IN App.css and graphicEvents.js *** */
@media (max-width: 699px), (max-height: 499px) {
	#blurb-box {
		display: none;
		position: absolute;
		top: 26px;
		right: 0;
		border: 1px #aaa solid;
	}
	div.nav-bar div {
		padding: .2em .4em;
	}
	div.nav-bar div.next-button {
		display: none;
	}
	p {
		margin: .2em 0;
	}
	h2 {
		margin: .2em 0;
	}
}
