.svg-chart {
	cursor: grab;
	background-color: black;
	color: white;
}

.svg-chart:active {
	cursor: grabbing;
}

.line-paths {
	fill: none;
	stroke: #009;
	stroke-width: .14em;
}
